import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import moment from 'moment'
import { useRouter } from 'next/router'

import { Controller, useForm } from 'react-hook-form'
import { usePassenger } from '../../context/PassengerContext'
import { api } from '../../config'
import { Spinner } from '../../utils/Spinner'

const Datepicker = dynamic(() => import('../../utils/DatePicker'))
const Dropdown = dynamic(() => import('../../utils/Dropdown'))

const tripType = [
  { id: 1, name: 'one way' },
  { id: 2, name: 'round trip' }
]

const numberOfPassengers = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 }
]

export default function Bookseat({ chnageLoader, getTerminals }) {
  const [loading, setLoading] = useState(false)
  const [trip, setTrip] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')
  const [arrivalTerminals, setArrivalTerminals] = useState()
  const [defaultValueAdults, setDefaultValueAdults] = useState({
    label: 1,
    value: 1
  })
  const router = useRouter()

  const { setGlobalState } = usePassenger()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    chnageLoader(false)
  }, [])

  const filterTerminals = async (terminal) => {
    setLoading(true)
    await api
      .get(`/Terminals/GetToTerminals?id=${terminal}`)
      .then((response) => {
        if (response.data && response.data.status_code === 200) {
          setLoading(false)
          setArrivalTerminals(response.data.data)
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.response) {
          setErrorMessage(error.response.data.error_message)
        } else if (error.request) {
          setErrorMessage('Please check your internet connection')
        } else {
          setErrorMessage('Something went wrong. Please try again')
        }
      })
  }

  const onSubmit = async (data) => {
    const { from, to, departureDate, returnDate, adults, children } = data

    const departure = moment(departureDate).format('YYYY-MM-DD')

    const params = {
      fromterminalId: from,
      toTerminalId: to,
      departureDate: departure,
      noOfAdults: adults,
      noOfChildren: children,
      isRoundTrip: trip === 2 ? true : false,
      returnDate: trip === 2 ? moment(returnDate).format('YYYY-MM-DD') : null
    }

    setLoading(true)
    await api
      .get(`/Bookings/GetBookings`, {
        params
      })
      .then(async (response) => {
        setLoading(false)
        setGlobalState({
          booking_cart_vm: response.data.data.booking_cart_vm,
          bookings: response.data.data.select_booking_dtos,
          traveling_detail: response.data.data.traveling_detail,
          routeDetails: response.data.data.route_detail,
          number_of_available_seat: response.data.data.number_of_available_seat
        })
        router.push('/bookingsummary')
      })
      .catch((error) => {
        setLoading(false)
        if (error.response) {
          setErrorMessage(
            'The selected route and date are fully booked, please change your departure date to book from available vehicles in new dates.'
          )
        } else if (error.request) {
          setErrorMessage('Please check your internet connection')
        } else {
          setErrorMessage('Something went wrong. Please try again')
        }
      })
  }

  return (
    <>
      <section className='border-solid border-bg-white rounded-xl py-6 lg:px-10 bg-white lg:w-18/19 mx-auto lg:mb-40 px-4 md:px-10 md:mb-16 relative'>
        <ul className='flex-row flex mb-8'>
          {tripType.map((singleTrip) => (
            <li
              role='button'
              onClick={() => setTrip(singleTrip.id)}
              key={singleTrip.id}
              className={
                (trip === singleTrip.id
                  ? 'bg-mainColor text-white'
                  : ' bg-tripColor text-primary') +
                ' border flex items-center justify-center p-2 mr-3 capitalize text-xs lg:text-sm transition duration-300 ease-in-out'
              }
            >
              {singleTrip.name}
            </li>
          ))}
        </ul>
        {errorMessage && (
          <p className='text-sm text-center text-red-500 my-2'>
            {errorMessage}
          </p>
        )}

        {loading && <Spinner />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className='grid lg:grid-cols-2 lg:gap-x-4 gap-y-4 lg:gap-y-7'>
            <Controller
              name='from'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a departure terminal'
                }
              }}
              render={({ field: { value, onChange, ref, name } }) => (
                <Dropdown
                  errorText={errors?.from?.message}
                  name={name}
                  ref={ref}
                  value={getTerminals?.data.find(
                    (opt) => opt.terminal_id === value
                  )}
                  labelText='Traveling from'
                  placeholder='Departure terminal'
                  dropDownOptions={getTerminals?.data}
                  onChange={(e) => {
                    onChange(e.terminal_id)
                    filterTerminals(e.terminal_id)
                  }}
                />
              )}
            />

            <Controller
              name='to'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a destination'
                }
              }}
              render={({ field: { value, onChange, ref, name } }) => (
                <Dropdown
                  errorText={errors?.to?.message}
                  name={name}
                  ref={ref}
                  value={arrivalTerminals?.find(
                    (opt) => opt.terminal_id === value
                  )}
                  labelText='Traveling to'
                  placeholder='Arrival terminal'
                  dropDownOptions={arrivalTerminals}
                  onChange={(e) => {
                    onChange(e.terminal_id)
                  }}
                />
              )}
            />

            <Controller
              name='departureDate'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a departure date'
                }
              }}
              render={({ field: { value, onChange, ref, name } }) => (
                <Datepicker
                  errorText={errors?.departureDate?.message}
                  name={name}
                  ref={ref}
                  selected={value}
                  onChange={(date) => onChange(date)}
                  labelText='Departure date'
                  calenderLabel='Please select a departure date'
                />
              )}
            />

            {trip === 2 && (
              <>
                <Controller
                  name='returnDate'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select a return date'
                    }
                  }}
                  render={({ field: { value, onChange, ref, name } }) => (
                    <Datepicker
                      errorText={errors?.returnDate?.message}
                      name={name}
                      ref={ref}
                      selected={value}
                      onChange={(date) => onChange(date)}
                      labelText='Return date'
                      calenderLabel='Please select a return date'
                    />
                  )}
                />
              </>
            )}
            <section className='flex flex-row items-center justify-between '>
              <section style={{ width: '49%' }}>
                <Controller
                  name='adults'
                  control={control}
                  defaultValue={defaultValueAdults.value}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select number of adults'
                    }
                  }}
                  render={({ field: { value, onChange, ref, name } }) => (
                    <Dropdown
                      errorText={errors?.adults?.message}
                      name={name}
                      ref={ref}
                      value={numberOfPassengers.find(
                        (opt) => opt.value === value
                      )}
                      defaultValue={defaultValueAdults}
                      labelText='Adults'
                      placeholder='Adults'
                      dropDownOptions={numberOfPassengers}
                      onChange={(e) => {
                        onChange(e.value)
                      }}
                    />
                  )}
                />
              </section>
              <section style={{ width: '49%' }}>
                <Controller
                  name='children'
                  control={control}
                  render={({ field: { value, onChange, ref, name } }) => (
                    <Dropdown
                      name={name}
                      ref={ref}
                      value={numberOfPassengers.find(
                        (opt) => opt.value === value
                      )}
                      labelText='Children'
                      placeholder='Children'
                      dropDownOptions={numberOfPassengers}
                      onChange={(e) => {
                        onChange(e.value)
                      }}
                    />
                  )}
                />
              </section>
            </section>
          </section>
          <section className='flex flex-row items-center justify-center my-12 md:w-1/5 mx-auto md:my-16 h-12 md:h-14 w-3/4'>
            <button
              type='submit'
              className='bg-mainColor h-full w-full rounded-lg items-center justify-center capitalize text-white text-sm font-regular hover:bg-hover transition duration-300 ease-in-out'
            >
              proceed
            </button>
          </section>
        </form>
      </section>
    </>
  )
}
