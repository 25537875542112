export const Spinner = () => {
    return (
      <section className='bg-spinner w-full h-full flex justify-center relative '>
        <section className='border-solid border border-white rounded-xl py-6 lg:px-10 bg-white w-96 h-48 z-20 absolute top-12'>
          <section className='flex flex-col items-center justify-center'>
            <svg
              className='animate-spin'
              width='28'
              height='28'
              viewBox='0 0 48 48'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM5.20286 24C5.20286 34.3814 13.6186 42.7971 24 42.7971C34.3814 42.7971 42.7971 34.3814 42.7971 24C42.7971 13.6186 34.3814 5.20286 24 5.20286C13.6186 5.20286 5.20286 13.6186 5.20286 24Z'
                fill='#E3E3E3'
              />
              <path
                d='M33.1934 4.67695C33.8107 3.37957 33.2625 1.81284 31.906 1.33956C29.0157 0.331154 25.9512 -0.117671 22.8773 0.0262718C18.9348 0.210894 15.0987 1.36497 11.7088 3.38628C8.31882 5.40758 5.47969 8.23372 3.44286 11.6144C1.40603 14.995 0.234372 18.8258 0.0316723 22.7674C-0.171028 26.709 0.601486 30.6398 2.28078 34.2115C3.96008 37.7833 6.49433 40.8858 9.65905 43.2441C12.8238 45.6025 16.5213 47.144 20.424 47.7321C23.4669 48.1906 26.5613 48.0585 29.5398 47.3519C30.9377 47.0203 31.6437 45.518 31.1627 44.1642C30.6818 42.8103 29.1955 42.1219 27.7882 42.4115C25.626 42.8563 23.3955 42.9182 21.1993 42.5873C18.1426 42.1267 15.2466 40.9194 12.768 39.0723C10.2893 37.2252 8.30445 34.7953 6.9892 31.9978C5.67395 29.2004 5.06891 26.1217 5.22766 23.0346C5.38642 19.9475 6.30408 16.9472 7.89935 14.2994C9.49463 11.6516 11.7183 9.43815 14.3733 7.85504C17.0284 6.27192 20.0329 5.36803 23.1207 5.22343C25.3393 5.11954 27.5517 5.40993 29.6569 6.07426C31.027 6.50663 32.5761 5.97433 33.1934 4.67695Z'
                fill='#F76A18'
              />
            </svg>
            <p className='text-primary text-base'>Processing</p>
          </section>
          <p className='text-center text-base text-news my-5'>
            Please wait a moment while we process your request
          </p>
        </section>
      </section>
    )
}